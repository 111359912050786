import { compact, some } from 'lodash';
import yup from '../../../components/form/CustomYupValidator';
import { getConvertedMoment, dateFormatter } from '../../../../services/dateFormatUtilsBeta';
import {
  currentDateTime,
  newMoment,
  serverDateToEndOfDayMoment,
} from '../../../services/dateFormatUtils';

const t = (key, params = {}) => I18n.t(`occurrences.incidents.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  incident_status_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  submission_channel_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  recipient_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  date: yup
    .string()
    .nullable()
    .test(
      'date_cannot_be_later_than_reported_date',
      t('date_cannot_be_later_than_reported_date'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(date) {
        const reportedDate = this.parent.reported_date;

        return date && reportedDate
          ? getConvertedMoment(date).isSameOrBefore(getConvertedMoment(reportedDate), 'minute')
          : true;
      },
    )
    .test(
      'date_cannot_be_later_than_corrective_actions_due_date',
      t('date_cannot_be_later_than_corrective_actions_due_date'),
      function _(date) {
        const correctiveActionsDueDate = this.parent.corrective_actions_due_date;

        return date && correctiveActionsDueDate
          ? getConvertedMoment(date).isSameOrBefore(
              serverDateToEndOfDayMoment(correctiveActionsDueDate, this.parent.date_zone),
              'minute',
            )
          : true;
      },
    )
    .test(
      'date_cannot_be_later_than_corrective_actions_completion_date',
      t('date_cannot_be_later_than_corrective_actions_completion_date'),
      function _(date) {
        const correctiveActionsCompletionDate = this.parent.corrective_actions_completion_date;

        return date && correctiveActionsCompletionDate
          ? getConvertedMoment(date).isSameOrBefore(
              serverDateToEndOfDayMoment(correctiveActionsCompletionDate, this.parent.date_zone),
              'minute',
            )
          : true;
      },
    )
    .test(
      'date_cannot_be_later_than_recurrence_prevention_due_date',
      t('date_cannot_be_later_than_recurrence_prevention_due_date'),
      function _(date) {
        const recurrencePreventionDueDate = this.parent.recurrence_prevention_due_date;

        return date && recurrencePreventionDueDate
          ? getConvertedMoment(date).isSameOrBefore(
              serverDateToEndOfDayMoment(recurrencePreventionDueDate, this.parent.date_zone),
              'minute',
            )
          : true;
      },
    )
    .test(
      'date_cannot_be_later_than_recurrence_prevention_completion_date',
      t('date_cannot_be_later_than_recurrence_prevention_completion_date'),
      function _(date) {
        const recurrencePreventionCompletionDate = this.parent
          .recurrence_prevention_completion_date;

        return date && recurrencePreventionCompletionDate
          ? getConvertedMoment(date).isSameOrBefore(
              serverDateToEndOfDayMoment(recurrencePreventionCompletionDate, this.parent.date_zone),
              'minute',
            )
          : true;
      },
    )
    .test(
      'date_cannot_be_later_than_current_time',
      t('date_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function _(date) {
        return date ? getConvertedMoment(date).isSameOrBefore(newMoment(), 'minute') : true;
      },
    )
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  reported_date: yup
    .string()
    .nullable()
    .test(
      'reported_date_cannot_be_later_than_response_date',
      t('reported_date_cannot_be_later_than_response_date'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(reportedDate) {
        const responseDate = this.parent.response_date;

        return reportedDate && responseDate
          ? getConvertedMoment(reportedDate).isSameOrBefore(
              getConvertedMoment(responseDate),
              'minute',
            )
          : true;
      },
    )
    .required(I18n.t('validations.required')),
  issue_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  short_description: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  issue: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  response_date: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  corrective_actions_due_date: yup
    .string()
    .nullable()
    .test(
      'corrective_actions_due_date_required_when_corrective_actions_present',
      t('corrective_actions_due_date_required_when_corrective_actions_present'),
      function _(correctiveActionsDueDate) {
        const correctiveActionsPresent = some(
          compact(
            [
              'corrective_actions_required',
              'actions_taken',
              'corrective_actions_completion_date',
              'corrective_actions_recorded_by_person_id',
            ].map(field => this.parent[field]),
          ),
        );
        return correctiveActionsDueDate || !correctiveActionsPresent;
      },
    ),
  recurrence_prevention_due_date: yup
    .string()
    .nullable()
    .test(
      'recurrence_prevention_due_date_required_when_recurrence_prevention_present',
      t('recurrence_prevention_due_date_required_when_recurrence_prevention_present'),
      function _(recurrencePreventionDueDate) {
        const recurrencePreventionPresent = some(
          compact(
            [
              'recurrence_prevention_required',
              'recurrence_prevention_comment',
              'recurrence_prevention_completion_date',
              'recurrence_prevention_recorded_by_person_id',
            ].map(field => this.parent[field]),
          ),
        );
        return recurrencePreventionDueDate || !recurrencePreventionPresent;
      },
    ),
  current_responsible_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
