import React, { useCallback, useState, useRef, useEffect } from 'react';
import { TrixEditor } from 'react-trix';
import ReactDOM from 'react-dom';
import { FastField } from 'formik';
import styled from 'styled-components';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { Modal } from '../modals/Modal';
import { API } from '../../services/api';
import { htmlOptionRenderer } from './selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from './AsyncSelectorField';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';
import { ModalSection } from '../modals/ModalSection';

const AddParameterDiv = styled.div`
  cursor: pointer;
  font-size: ${fontSize.textMd};
  color: ${colors.link};
  display: inline;
  &:hover {
    text-decoration: underline;
  }
`;

export const RichTextField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched, values },
    disabled,
    placeholder,
    onChange,
    withParameters,
  } = props;
  const wrapperRef = useRef(null);
  const modalRef = useRef(null);

  const [focused, setFocused] = useState(false);
  const [editorInstance, setEditorInstance] = useState(false);

  const defaultOnChange = useCallback(
    async newValue => {
      await setFieldValue(name, newValue);
      await setFieldTouched(name, true);
    },
    [setFieldValue, setFieldTouched, name],
  );
  const internalOnChange = useCallback(
    newValue => (onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue)),
    [onChange, defaultOnChange],
  );

  useEffect(() => {
    if (withParameters) {
      const toolbar = wrapperRef.current.getElementsByTagName('trix-toolbar')[0];
      const addParameterDiv = document.createElement('div');

      addParameterDiv.setAttribute('id', `add-parameter-${toolbar.id}`);
      addParameterDiv.setAttribute('style', `margin-bottom: 10px`);
      toolbar.parentNode.insertBefore(addParameterDiv, toolbar.nextSibling);
      ReactDOM.render(
        <AddParameterDiv
          role="link"
          tabindex="0"
          onMouseDown={async () => {
            await modalRef.current.open();
          }}
        >
          + Add Parameter
        </AddParameterDiv>,
        document.getElementById(`add-parameter-${toolbar.id}`),
      );
    }
  }, []);

  useEffect(() => {
    const toolbar = wrapperRef.current.getElementsByTagName('trix-toolbar')[0];
    const addParameterLink = document.getElementById(`add-parameter-${toolbar.id}`);
    if (addParameterLink) {
      addParameterLink.style.display = focused ? 'block' : 'none';
    }
    toolbar.style.display = focused ? 'block' : 'none';
  }, [focused]);

  const handleParameterChange = useCallback(
    e => {
      const lastIndex = editorInstance.getDocument().toString().length - 1;
      editorInstance.setSelectedRange([lastIndex, lastIndex]);
      editorInstance.insertString(` .:${e.value}:.`);
    },
    [editorInstance],
  );
  return (
    <>
      <FieldWrapper {...fieldWrapperProps(props)}>
        <div
          ref={wrapperRef}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          className={`sp-portal-pack-trix-wrapper ${disabled ? 'trix-editor-disabled' : ''}`}
        >
          <TrixEditor
            value={value}
            placeholder={placeholder}
            onChange={internalOnChange}
            onEditorReady={e => setEditorInstance(e)}
          />
        </div>
      </FieldWrapper>
      <Modal title="Select Attribute" ref={modalRef}>
        <ModalSection>
          <FastField
            api={API.selectors.customEmailTemplates.modelAttributes.index}
            apiParams={{ model: values.related_object }}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            displayMode={fieldDisplayModes.NO_WRAPPER}
            disabled={!values.related_object}
            value={null}
            onChange={selected => {
              modalRef.current.hide();
              handleParameterChange(selected);
            }}
          />
        </ModalSection>
      </Modal>
    </>
  );
};
