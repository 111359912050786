import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { API } from '../../../services/api';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { RichTextField } from '../../../components/fields/RichTextField';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { Footer } from './Footer';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';

const t = (key, params) => I18n.t(`custom_email_templates.new_edit.${key}`, params);

export const Form = props => {
  const {
    values: { id, related_object },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.custom_email_template',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.custom_email_template'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField
            name="related_object"
            api={API.selectors.customEmailTemplates.models.index}
            selectionApi={API.selectors.customEmailTemplates.models.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            required
            disabled={isDefined(id)}
          />
        </FormSection>
        <FormSection title={t('sections.template')}>
          <FastField name="subject" required component={TextField} />
          <FastField
            required
            name="content"
            component={RichTextField}
            withParameters
            parametersOptions={related_object}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      {isDefined(id) && (
        <FormSection>
          <IndexTableDefaultHandler
            title={t('lists.assessment_types.title')}
            api={API.administration.assessmentTypes.index}
            fixedFilters={{ with_custom_email_template_id: id }}
            idToHrefFunction={_id => `/administration/inspection_types/${_id}/edit`}
            manageColumnsModel="InspectionType"
            forceEmpty={!isDefined(id)}
          />
        </FormSection>
      )}
    </form>
  );
};
