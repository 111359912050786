import { withFormik } from 'formik';
import _ from 'lodash';
import {
  isDefined,
  prepareFormFunctions,
  prepareHasManyRelationToSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { inspectionTypeEmailActions } from '../../../services/customEmailTemplatesUtils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`administration.inspection_types.new_edit.${key}`);
const t_external = key => I18n.t(`administration.external_inspection_types.new_edit.${key}`);

const InspectionTypeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const inspectionType = InspectionTypeFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(inspectionType.id)) {
      InspectionTypeFunctions.update(inspectionType, formikProps, values.afterSave);
    } else {
      InspectionTypeFunctions.create(inspectionType, formikProps, values.afterSave);
    }
  },
  prepareValuesToSubmit: values => {
    // eslint-disable-next-line max-len
    const finalInspectionFindingDefinitionsTypesAttributes = InspectionTypeFunctions.prepareInspectionFindingDefinitionsTypeAttributes(
      values.inspectionFindingDefinitionsPerCategory,
    );
    values.inspection_finding_definitions_types_attributes = prepareHasManyRelationToSubmit(
      values.initialInspectionFindingDefinitionsTypesAttributes,
      finalInspectionFindingDefinitionsTypesAttributes,
    );
    return InspectionTypeFunctions.applyBackendValuesWhitelist(
      InspectionTypeFunctions.prepareCustomEmailTemplatesAttributes(values),
    );
  },
  prepareInspectionFindingDefinitionsTypeAttributes: inspectionFindingDefinitionsPerCategory => {
    let result = [];

    inspectionFindingDefinitionsPerCategory.forEach(({ inspection_finding_definitions_types }) => {
      const validInspectionFindingDefinitionTypes = inspection_finding_definitions_types.filter(
        definitionType => isDefined(definitionType.inspection_finding_definition_id),
      );
      result = [...result, ...validInspectionFindingDefinitionTypes];
    });

    result = result.map((definitionType, index) => {
      definitionType.question_order = index;
      return definitionType;
    });

    return result;
  },
  prepareCustomEmailTemplatesAttributes: values => {
    inspectionTypeEmailActions.forEach(name => {
      if (!values[`${_.camelCase(name)}CustomTemplate`]) values[`${name}_email_template_id`] = null;
    });
    return values;
  },
  create: async (inspectionType, formikProps, afterSave) => {
    const { internalInspectionType } = formikProps.props;
    const translator = internalInspectionType ? t : t_external;
    const apiPath = internalInspectionType
      ? API.administration.assessmentTypes
      : API.administration.externalInspectionTypes;

    const { ok, data } = await apiPath.create({
      inspection_type: inspectionType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/inspection_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(
        I18n.t(
          `breadcrumbs.administration.${
            internalInspectionType ? '' : 'external_'
          }inspection_types.edit.title`,
        ),
      );
      formikProps.resetForm({
        values: InspectionTypeFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id, data.data.attributes);
      showSuccessMessage(translator('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(translator('flash.error.save'), data);
    }
  },
  update: async (inspectionType, formikProps, afterSave) => {
    const { internalInspectionType } = formikProps.props;
    const translator = internalInspectionType ? t : t_external;
    const apiPath = internalInspectionType
      ? API.administration.assessmentTypes
      : API.administration.externalInspectionTypes;

    const { ok, data } = await apiPath.update(inspectionType.id, {
      inspection_type: inspectionType,
    });
    if (ok) {
      formikProps.resetForm({
        values: InspectionTypeFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id, data.data.attributes);
      showSuccessMessage(translator('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(translator('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: inspectionType => ({
    anyRelatedInspections: inspectionType.any_related_inspections,
    objectTypeConfiguration: inspectionType.object_type_configuration,
    // eslint-disable-next-line max-len
    initialInspectionFindingDefinitionsTypesAttributes: InspectionTypeFunctions.prepareInspectionFindingDefinitionsTypeAttributes(
      inspectionType.inspection_finding_definitions_per_category,
    ),
    inspectionFindingDefinitionsPerCategory:
      inspectionType.inspection_finding_definitions_per_category,
    // eslint-disable-next-line max-len
    responsiblePersonAssignedCustomTemplate: !!inspectionType.responsible_person_assigned_email_template_id,
    inspectorAssignedCustomTemplate: !!inspectionType.inspector_assigned_email_template_id,
    // eslint-disable-next-line max-len
    alsoNotifyPersonAssignedCustomTemplate: !!inspectionType.also_notify_person_assigned_email_template_id,
    finalizedCustomTemplate: !!inspectionType.finalized_email_template_id,
    reopenedCustomTemplate: !!inspectionType.reopened_email_template_id,
    // eslint-disable-next-line max-len
    finalizedAndSendTableauReportCustomTemplate: !!inspectionType.finalized_and_send_tableau_report_email_template_id,
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'inspection_type_name',
    'people_group_id',
    'archive_it',
    'allow_cp_findings',
    'permits_fdny',
    'equipment',
    'construction_project',
    'biological_safety',
    'location',
    'department',
    'safe_work_authorization',
    'incident',
    'accident',
    'rad_inventory_item',
    'rad_license',
    'rad_purchase',
    'rad_permit',
    'inspection_finding_definitions_types_attributes',
    'should_capture_investigable_snapshot',
    'should_prepopulate_answers',
    'info_box_show',
    'info_box_type',
    'info_box_content',
    ...inspectionTypeEmailActions.map(action => `${action}_email_template_id`),
  ],
  preprocessBackendValues: backendValues => {
    const objectTypeConfiguration = Object.fromEntries(
      Object.entries(backendValues.object_type_configuration).map(([key, val]) => [key, val]),
    );
    if (!backendValues.id) {
      backendValues.identifier = '##########';
    }
    return {
      ...backendValues,
      ...objectTypeConfiguration,
    };
  },
});

export const InspectionTypeForm = withFormik({
  mapPropsToValues: props => InspectionTypeFunctions.prepareInitialValues(props.inspectionType),
  handleSubmit: InspectionTypeFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
