import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { TextField } from '../../../../components/fields/TextField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { LocationFields } from '../../../../components/fields/compositeFields/LocationFields';
import { RichTextField } from '../../../../components/fields/RichTextField';
import { AsyncMultiSelectorField } from '../../../../components/fields/AsyncMultiSelectorField';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { produceShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../../components/fields/SelectorField';

export const Details = props => {
  const {
    t,
    defaultChildrenAttributes,
    setFieldValue,
    enums: { timeZones },
    values: {
      date_zone,
      auxiliaryDateZone,
      reported_date_zone,
      auxiliaryReportedDateZone,
      submissionChannelName,
    },
  } = props;

  return (
    <FormSection
      title={t('sections.details')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        name="submission_channel_id"
        required
        api={API.selectors.submissionChannels.index}
        selectionApi={API.selectors.submissionChannels.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
        onChange={(e, defaultOnChange) => {
          defaultOnChange(e);
          setFieldValue('submissionChannelName', e?.data?.text);
        }}
      />
      <FastField
        name="submission_channel_other"
        component={TextField}
        hidden={submissionChannelName !== 'Other'}
      />
      <FastField
        name="recipient_person_id"
        required
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
        hint={t('hints.recipient_person')}
      />
      <FastField
        name="date"
        required
        component={DateTimeField}
        timeZones={{
          fixedTimeZone: date_zone,
          inputTimeZone: auxiliaryDateZone,
        }}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          [
            'date_zone',
            'auxiliaryDateZone',
            'reported_date',
            'corrective_actions_due_date',
            'corrective_actions_completion_date',
            'recurrence_prevention_due_date',
            'recurrence_prevention_completion_date',
          ],
        )}
      />
      <FastField
        name="auxiliaryDateZone"
        options={mapToSelectorOptions(timeZones, 'header', 'value')}
        component={SelectorField}
        label=""
      />
      <LocationFields {...props} buildingSelectorAttributes={{ required: true }} />
      <FastField
        name="department_id"
        api={API.selectors.departments.index}
        selectionApi={API.selectors.departments.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField
        name="caller_person_id"
        required
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
        hint={t('hints.caller_person')}
      />
      <FastField
        name="external_caller_name"
        component={TextField}
        label=""
        placeholder={t('placeholders.external_caller_name')}
      />
      <FastField
        name="reported_date"
        required
        component={DateTimeField}
        timeZones={{
          fixedTimeZone: reported_date_zone,
          inputTimeZone: auxiliaryReportedDateZone,
        }}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          ['reported_date_zone', 'auxiliaryReportedDateZone', 'response_date'],
        )}
      />
      <FastField
        name="auxiliaryReportedDateZone"
        options={mapToSelectorOptions(timeZones, 'header', 'value')}
        component={SelectorField}
        label=""
      />
      <FastField name="follow_up_contact" component={TextField} />
      <FastField
        name="owner_person_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
        hint={t('hints.owner_person')}
      />
      <FastField
        name="issue_type_id"
        required
        api={API.selectors.issueTypes.index}
        selectionApi={API.selectors.issueTypes.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField name="short_description" required component={TextField} />
      <FastField name="issue" required component={RichTextField} />
      <FastField
        name="label_ids"
        api={API.selectors.labels.index}
        selectionApi={API.selectors.labels.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncMultiSelectorField}
      />
    </FormSection>
  );
};
