import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`custom_email_templates.new_edit.${key}`);

const CustomEmailTemplateFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const customEmailTemplate = CustomEmailTemplateFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(customEmailTemplate.id)) {
      CustomEmailTemplateFunctions.update(customEmailTemplate, formikProps, values);
    } else {
      CustomEmailTemplateFunctions.create(customEmailTemplate, formikProps, values);
    }
  },
  prepareValuesToSubmit: values => {
    return CustomEmailTemplateFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (customEmailTemplate, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.customEmailTemplates.create({
      custom_email_template: customEmailTemplate,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/custom_email_templates/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.custom_email_templates.edit.title'));
      formikProps.resetForm({
        values: CustomEmailTemplateFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (customEmailTemplate, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.customEmailTemplates.update(
      customEmailTemplate.id,
      {
        custom_email_template: customEmailTemplate,
      },
    );
    if (ok) {
      formikProps.resetForm({
        values: CustomEmailTemplateFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'subject',
    'header',
    'content',
    'footer',
    'related_object',
  ],
});

export const CustomEmailTemplateForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    CustomEmailTemplateFunctions.prepareInitialValues({
      ...props.customEmailTemplate,
    }),
  handleSubmit: CustomEmailTemplateFunctions.handleSubmit,
})(Form);
