import React from 'react';
import { FastField, withFormik } from 'formik';
import { ModalSection } from '../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import yup from '../../../components/form/CustomYupValidator';

const t = (key, props = {}) =>
  I18n.t(`occurrences.accidents.new_edit.modals.link_incident.${key}`, props);

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit } = props;

  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.accident',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.incident'),
          }}
        >
          <FastField
            name="investigable_id"
            required
            api={API.selectors.incidents.index}
            selectionApi={API.selectors.incidents.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  investigable_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

export const AddRelatedIncidentModalContent = withFormik({
  mapPropsToValues: props => ({
    accident_id: props.values.id,
    investigable_id: '',
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, setFieldValue, listRef } = formikProps.props;
    const api = API.occurrences.accidents;
    const { ok, data } = await api.update(values.accident_id, {
      accident: values,
      submit_type: 'link_incident',
    });
    if (ok) {
      formikProps.resetForm();
      setFieldValue('relatedIncident', data.data.attributes.related_incident);
      hide();
      listRef.current.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  validate: produceDefaultValidation(validationSchema),
})(Form);
