// TODO: change the name of the file and remove its legacy version - to be addressed in the consecutive PR

import moment from 'moment-timezone';

// One should not use dates without parsing them with getConvertedMoment
// function - this way we want to avoid time zone handling issues.

const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';
const SERVER_DATE_TIME_FORMAT_2 = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ';

const WORDY_FORMAT = 'll';
const DATE_FORMAT = 'L';
const DATE_TIME_FORMAT = 'L LTS';
const TIME_FORMAT = 'hh:mm A';
const TIME_SECONDS_FORMAT = 'hh:mm:ss A';

const formatMatches = (inputDate, formats) => moment(inputDate, formats, true).isValid();

// allowTimeZoneInsensitive flag is here to ensure that one is perfectly
// aware that he should not rely on nor display time from a time zone
// insensitive date.
export const getConvertedMoment = (inputDate, allowTimeZoneInsensitive = false) => {
  if (!inputDate) {
    return undefined;
  }
  let whitelist = [SERVER_DATE_TIME_FORMAT, SERVER_DATE_TIME_FORMAT_2];
  if (allowTimeZoneInsensitive) {
    whitelist = [...whitelist, ...[SERVER_DATE_FORMAT]];
  }
  const timeZone = Cookies.get('user.time_zone');

  for (let i = 0; i < whitelist.length; i++) {
    const format = whitelist[i];
    if (formatMatches(inputDate, format)) {
      return moment.tz(inputDate, format, timeZone);
    }
  }
  throw new Error('Tried to convert a date with the unknown/inappropriate format.');
};

const getFormattedDate = (inputDate, outputFormat, allowTimeZoneInsensitive = false) => {
  const convertedMoment = getConvertedMoment(inputDate, allowTimeZoneInsensitive);
  if (!convertedMoment) {
    return undefined;
  }
  return convertedMoment.format(outputFormat);
};

export const newMoment = () => {
  const timeZone = Cookies.get('user.time_zone');
  return moment().tz(timeZone);
};

export const dateFormatValidator = {
  isServerDate: (inputDate, allowFalsy = true) =>
    !inputDate ? allowFalsy : formatMatches(inputDate, SERVER_DATE_FORMAT),
  isServerDateTime: (inputDate, allowFalsy = true) =>
    !inputDate
      ? allowFalsy
      : formatMatches(inputDate, SERVER_DATE_TIME_FORMAT) ||
        formatMatches(inputDate, SERVER_DATE_TIME_FORMAT_2),
  isDate: (inputDate, allowFalsy = true) =>
    !inputDate ? allowFalsy : formatMatches(inputDate, DATE_FORMAT),
};

export const dateFormatter = {
  serverDate: inputDate => getFormattedDate(inputDate, SERVER_DATE_FORMAT, true),
  serverDateTime: inputDate => getFormattedDate(inputDate, SERVER_DATE_TIME_FORMAT),
  wordy: inputDate => getFormattedDate(inputDate, WORDY_FORMAT),
  date: inputDate => getFormattedDate(inputDate, DATE_FORMAT, true),
  dateTime: inputDate => getFormattedDate(inputDate, DATE_TIME_FORMAT),
  time: inputDate => getFormattedDate(inputDate, TIME_FORMAT),
  timeSeconds: inputDate => getFormattedDate(inputDate, TIME_SECONDS_FORMAT),
};

// Please note that this helper do not guarantee time zone compliance
// and should be used with extra caution.
export const momentFormatter = {
  serverDate: m => m.format(SERVER_DATE_FORMAT),
  serverDateTime: m => m.format(SERVER_DATE_TIME_FORMAT),
  wordy: m => m.format(WORDY_FORMAT),
  date: m => m.format(DATE_FORMAT),
  dateTime: m => m.format(DATE_TIME_FORMAT),
  time: m => m.format(TIME_FORMAT),
  timeSeconds: m => m.format(TIME_SECONDS_FORMAT),
};
