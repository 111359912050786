import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { FilesField } from '../../../../components/fields/FilesField';
import { LinksField } from '../../../../components/fields/LinksField';

export const ReportAttachments = props => {
  const { t, defaultChildrenAttributes } = props;

  return (
    <FormSection
      title={t('sections.report_attachments')}
      defaultChildrenAttributes={defaultChildrenAttributes}
      hint={t('hints.report_attachment_header')}
    >
      <FastField name="report_attachments" component={FilesField} />
      <FastField name="report_links" component={LinksField} />
    </FormSection>
  );
};
