import React, { useMemo, useState } from 'react';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { defaultShouldUpdate } from '../../../../services/formHelper';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { Overview } from './sections/Overview';
import { Details } from './sections/Details';
import { Response } from './sections/Response';
import { Summary } from './sections/Summary';
import { CorrectiveActions } from './sections/CorrectiveActions';
import { PreventativeActions } from './sections/PreventativeActions';
import { ReportAttachments } from './sections/ReportAttachments';
import { InternalInformation } from './sections/InternalInformation';
import { Assign } from './sections/Assign';
import { Footer } from './Footer';
import { RelatedIncidentRequests } from './sections/RelatedIncidentRequests';
import { EventTypeDetails } from './sections/EventTypeDetails';

const t = (key, params = {}) => I18n.t(`occurrences.incidents.new_edit.${key}`, params);

export const Form = props => {
  const {
    values: { id, identifier, finalized },
    currentUser,
  } = props;

  const [typeDetailsLoading, setTypeDetailsLoading] = useState(false);
  const defaultChildrenAttributes = useMemo(
    () => ({
      labelI18nKeyPrefix: 'activerecord.attributes.incident',
      shouldUpdate: defaultShouldUpdate,
      resourceName: I18n.t('resource_names.incident'),
      disabled: finalized,
    }),
    [defaultShouldUpdate, finalized],
  );

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/occurrences/incidents/${id}/audits` : ''}
        pdfExportLink={
          isDefined(id) ? `/salute_portal/api/occurrences/incidents/incidents/${id}.pdf` : ''
        }
        pdfExportMessage={I18n.t(`flash_message.async_report_render`, {
          user_email: currentUser.email,
        })}
        investigable={
          isDefined(id) && {
            id,
            identifier,
            type: 'Incident',
          }
        }
      />
      <RelatedIncidentRequests t={t} {...props} />
      <DefaultAttributesGroup defaultChildrenAttributes={defaultChildrenAttributes}>
        <Overview t={t} setTypeDetailsLoading={setTypeDetailsLoading} {...props} />
        <Details t={t} {...props} />
        <Response t={t} {...props} />
        <Summary t={t} {...props} />
        <CorrectiveActions t={t} {...props} />
        <PreventativeActions t={t} {...props} />
        <ReportAttachments t={t} {...props} />
        <InternalInformation t={t} {...props} />
        <Assign t={t} {...props} />
        <EventTypeDetails loading={typeDetailsLoading} {...props} />
      </DefaultAttributesGroup>
      <Footer t={t} {...props} />
    </form>
  );
};
