import { withFormik } from 'formik';
import {
  isDefined,
  prepareCustomFieldsAnswers,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = (key, attributes) => I18n.t(`inventory.equipment.equipment.new_edit.${key}`, attributes);

const EquipmentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const equipment = EquipmentFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(equipment.id)) {
      EquipmentFunctions.update(equipment, formikProps, values);
    } else {
      EquipmentFunctions.create(equipment, formikProps, values);
    }
  },
  create: async (equipment, formikProps, values) => {
    const { afterSave, equipmentTypeName } = values;
    const { ok, data } = await API.equipment.equipment.create({ equipment });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/inventory/equipment/equipment/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(
        I18n.t('breadcrumbs.inventory.equipment.equipment.edit.title', {
          equipment_type: equipmentTypeName,
        }),
      );
      formikProps.resetForm({
        values: EquipmentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { equipment_type: equipmentTypeName }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { equipment_type: equipmentTypeName }), data);
    }
  },
  update: async (equipment, formikProps, values) => {
    const { afterSave, equipmentTypeName } = values;
    const { ok, data } = await API.equipment.equipment.update(equipment.id, { equipment });
    if (ok) {
      formikProps.resetForm({
        values: EquipmentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { equipment_type: equipmentTypeName }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { equipment_type: equipmentTypeName }), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: equipment => {
    return {
      afterSave: () => {},
      equipmentTypeName: equipment.equipment_type_name,
      equipmentFieldDefinitions: equipment.equipment_field_definitions,
    };
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'equipment_type_id',
    'manufacturer',
    'model',
    'serial_no',
    'date_installed',
    'date_removed',
    'equipment_status_id',
    'equip_use',
    'alternate_equipment_name',
    'last_internal_inspection_date',
    'last_external_inspection_date',
    'comments',
    'building_id',
    'bldg_flr_id',
    'location_lab',
    'location_other',
    'responsible_person1_id',
    'responsible_person2_id',
    'responsible_person3_id',
    'owner',
    'links',
    'attachments',
    'equipment_field_answers_attributes',
    'internal_inspection_frequency',
    'next_internal_inspection_date',
    'external_inspection_frequency',
    'next_external_inspection_date',
    'imported',
    'label_ids',
  ],
  purposefullyNotSnakeCasedBackendValues: [
    'responsible_person1_id',
    'responsible_person2_id',
    'responsible_person3_id',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    equipment_field_answers_attributes: prepareCustomFieldsAnswers(
      'equipment',
      backendValues.equipment_field_definitions,
      backendValues.equipment_field_answers_attributes,
    ),
  }),
});

export const EquipmentForm = withFormik({
  mapPropsToValues: props => EquipmentFunctions.prepareInitialValues(props.equipment),
  handleSubmit: EquipmentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
