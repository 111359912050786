import React from 'react';
import { FastField, withFormik } from 'formik';
import { ModalSection } from '../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import yup from '../../../components/form/CustomYupValidator';

const t = (key, props = {}) =>
  I18n.t(`occurrences.incidents.new_edit.modals.link_incident_request.${key}`, props);

const Form = props => {
  const {
    hide,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { incident_id },
    requestResourceName,
  } = props;

  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.incident',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.incident_requests_incident'),
          }}
        >
          <FastField
            name="incident_request_id"
            required
            label={requestResourceName}
            api={API.selectors.incidentRequests.index}
            selectionApi={API.selectors.incidentRequests.show}
            apiParams={{ without_incident_id: incident_id }}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  incident_request_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

export const AddIncidentRequestModalContent = withFormik({
  mapPropsToValues: props => ({
    incident_id: props.values.id,
    incident_request_id: '',
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, listRef, requestResourceName } = formikProps.props;
    const api = API.occurrences.incidents.incidentRequests;
    const { ok, data } = await api(values.incident_id).create({
      incident_requests_incident: values,
    });
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.link', { resourceName: requestResourceName }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.link', { resourceName: requestResourceName }), data);
    }
  },
  validate: produceDefaultValidation(validationSchema),
})(Form);
