import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { RichTextField } from '../../../../components/fields/RichTextField';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { mapToSelectorOptions, produceShouldUpdate } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../../components/fields/SelectorField';

export const Response = props => {
  const {
    t,
    defaultChildrenAttributes,
    enums: { timeZones },
    values: { response_date_zone, auxiliaryResponseDateZone },
  } = props;

  return (
    <FormSection
      title={t('sections.response')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        name="responder_person_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
        hint={t('hints.responder_person')}
      />
      <FastField
        name="response_date"
        component={DateTimeField}
        timeZones={{
          fixedTimeZone: response_date_zone,
          inputTimeZone: auxiliaryResponseDateZone,
        }}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          ['response_date_zone', 'auxiliaryResponseDateZone'],
        )}
      />
      <FastField
        name="auxiliaryResponseDateZone"
        options={mapToSelectorOptions(timeZones, 'header', 'value')}
        component={SelectorField}
        label=""
      />
      <FastField
        name="responder_assessment"
        component={RichTextField}
        hint={t('hints.responder_assessment')}
      />
    </FormSection>
  );
};
