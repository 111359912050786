import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  subject: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  content: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  related_object: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
