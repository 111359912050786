import React from 'react';
import { FastField } from 'formik';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { RichTextField } from '../../../../components/fields/RichTextField';
import { mapToSelectorOptions } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { NumericField } from '../../../../components/fields/NumericField';
import { FormSection } from '../../../../components/form/FormSection';
import { FilesField } from '../../../../components/fields/FilesField';
import { LinksField } from '../../../../components/fields/LinksField';

export const InternalInformation = props => {
  const {
    t,
    defaultChildrenAttributes,
    enums: { costImplication },
  } = props;

  return (
    <FormSection
      title={t('sections.internal_information')}
      defaultChildrenAttributes={defaultChildrenAttributes}
      hint={t('hints.internal_information_header')}
    >
      <FastField name="other_information" component={RichTextField} />
      <FastField
        name="impact_type_id"
        api={API.selectors.impactTypes.index}
        selectionApi={API.selectors.impactTypes.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField name="impact_length" component={NumericField} />
      <FastField
        name="incident_risk_rating_id"
        api={API.selectors.incidentRiskRatings.index}
        selectionApi={API.selectors.incidentRiskRatings.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField
        name="cost_implication"
        options={mapToSelectorOptions(costImplication, 'header', 'value')}
        hint={t('hints.cost_implication')}
        component={SelectorField}
      />
      <FastField
        name="report_to_regulatory_agency"
        options={[
          { value: true, data: I18n.t('true') },
          { value: false, data: I18n.t('false') },
        ]}
        component={SelectorField}
      />
      <FastField name="attachments" component={FilesField} />
      <FastField name="links" component={LinksField} />
    </FormSection>
  );
};
