import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { Modal } from '../../../components/modals/Modal';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { SelectorField } from '../../../components/fields/SelectorField';
import { mapToSelectorOptions } from '../../../services/fieldUtils';

const t = key => I18n.t(`occurrences.accidents.new_edit.${key}`);
const resolutionModalT = key => t(`modals.resolution.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id, finalized },
    enums: { finalized_status },
  } = props;

  const resolutionModalRef = useRef(null);
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting, hidden: finalized }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.accident')}
            onSubmit={async () => {
              const { ok, data } = await API.occurrences.accidents.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete'));
                window.location.href = `/occurrences/accidents`;
              } else {
                showBackendErrorMessage(t('flash.error.delete'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <LightButton
            hidden={!finalized}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
          <BrandButton
            hidden={finalized}
            onClick={() => {
              setFieldValue('submitType', 'finalize');
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.finalize')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title')} ref={resolutionModalRef}>
        <ModalSection>
          <FastField
            name="status"
            label={resolutionModalT('status')}
            required
            options={mapToSelectorOptions(finalized_status, 'header', 'value')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            component={SelectorField}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => resolutionModalRef.current.hide()}>
                {resolutionModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.finalize')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
