import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { CustomFields } from '../../../../components/fields/compositeFields/CustomFields';
import { FormSection } from '../../../../components/form/FormSection';
import { Loader } from '../../../../components/Loader';
import { isDefined } from '../../../../../community_portal/assets/helpers/GeneralHelper';

const t = (key, params) => I18n.t(`requests.incident_requests.new_edit.${key}`, params);

const LoaderWrapper = styled.div`
  margin-top: 20px;
`;

export const EventTypeDetails = props => {
  const {
    loading,
    values: { eventTypeName, incidentRequestFieldDefinitions },
  } = props;

  if (loading)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  const renderTypeDetails = isDefined(eventTypeName) && !isEmpty(incidentRequestFieldDefinitions);
  if (!renderTypeDetails) return <></>;

  return (
    <FormSection title={t('sections.event_type_details', { event_type: eventTypeName })}>
      <CustomFields entityName="incident" entityDefinitionName="incident_request" {...props} />
    </FormSection>
  );
};
