import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { RichTextField } from '../../../../components/fields/RichTextField';

export const Summary = props => {
  const { t, defaultChildrenAttributes } = props;

  return (
    <FormSection
      title={t('sections.summary')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        name="executive_summary"
        component={RichTextField}
        hint={t('hints.executive_summary')}
      />
    </FormSection>
  );
};
