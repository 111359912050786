import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { CustomEmailTemplateForm } from './Form';

export const CustomEmailTemplate = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [customEmailTemplate, setCustomEmailTemplate] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.administration.customEmailTemplates.show
        : API.administration.customEmailTemplates.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setCustomEmailTemplate(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <CustomEmailTemplateForm {...props} customEmailTemplate={customEmailTemplate} />;
};
export default CustomEmailTemplate;
