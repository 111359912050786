import React, { useCallback } from 'react';
import camelCase from 'lodash/camelCase';
import { FastField } from 'formik';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { renderCustomInput } from '../auxiliary/renderCustomInput';

const buildOptionsToSelector = (variant, selectorValues) => {
  if (variant === 'selector') {
    return selectorValues.map(value => ({
      value,
      data: value,
    }));
  }
  return null;
};

export const CustomFields = props => {
  const {
    entityName,
    resourceName,
    values,
    setFieldValue,
    disabled,
    entityDefinitionName = null,
  } = props;

  const entityFieldDefinitionName = entityDefinitionName || entityName;
  const fieldDefinitions = values[`${camelCase(entityFieldDefinitionName)}FieldDefinitions`];
  const answers = values[`${entityName}_field_answers_attributes`];

  const checkboxOnChange = name => e => {
    const newValue = String(e.target.checked);
    setFieldValue(name, newValue);
  };

  const findAnswerIndex = useCallback(
    fieldDefinitionId =>
      answers.findIndex(
        answer => answer[`${entityFieldDefinitionName}_field_definition_id`] === fieldDefinitionId,
      ),
    [entityFieldDefinitionName, answers],
  );

  return (
    <>
      {fieldDefinitions.map(fieldDefinition => {
        const answerIndex = findAnswerIndex(fieldDefinition.id);
        const fieldName = `${entityName}_field_answers_attributes.${answerIndex}.answer`;
        const onChange = fieldDefinition.variant === 'boolean' ? checkboxOnChange(fieldName) : null;
        return (
          <FastField
            key={fieldDefinition.name}
            name={fieldName}
            label={fieldDefinition.name}
            resourceName={resourceName}
            shouldUpdate={defaultShouldUpdate}
            placeholder={fieldDefinition.placeholder}
            onChange={onChange}
            hint={fieldDefinition.tooltip}
            disabled={disabled}
            component={renderCustomInput(fieldDefinition.variant)}
            options={buildOptionsToSelector(
              fieldDefinition.variant,
              fieldDefinition.selector_values,
            )}
          />
        );
      })}
    </>
  );
};
