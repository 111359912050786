import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';

export const Assign = props => {
  const { t, defaultChildrenAttributes } = props;

  return (
    <FormSection title={t('sections.assign')} defaultChildrenAttributes={defaultChildrenAttributes}>
      <FastField
        name="current_responsible_person_id"
        required
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
        hint={t('hints.responsible_person')}
      />
      <FastField
        name="sendEmailNotifications"
        label={t('other.send_email_notifications')}
        component={CheckBoxField}
      />
    </FormSection>
  );
};
