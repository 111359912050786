import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
  prepareCustomFieldsAnswers,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`occurrences.accidents.new_edit.${key}`, params);

const AccidentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const accident = AccidentFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(accident.id)) {
      AccidentFunctions.create(accident, formikProps, values);
    } else {
      AccidentFunctions.update(accident, formikProps, values);
    }
  },
  prepareValuesToSubmit: values => {
    values.datetime_reported_zone = values.auxiliaryDatetimeReportedZone;
    values.datetime_of_occurrence_zone = values.auxiliaryDatetimeOfOccurrenceZone;
    values.response_date_zone = values.auxiliaryResponseDateZone;

    return AccidentFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (accident, formikProps, { afterSave, submitType }) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.occurrences.accidents.create({
      accident,
      submit_type: submitType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/occurrences/accidents/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit', { resource_name }));
      formikProps.resetForm({
        values: AccidentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (accident, formikProps, { afterSave, submitType }) => {
    const { resource_name } = formikProps.props;
    const response = await API.occurrences.accidents.update(accident.id, {
      accident,
      submit_type: submitType,
    });
    const { ok, data } = response;
    if (ok) {
      formikProps.resetForm({
        values: AccidentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${snakeCase(submitType)}`, { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${snakeCase(submitType)}`, { resource_name }), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: accident => ({
    submitType: 'save',
    finalized: accident.finalized,
    eventTypeName: accident.event_type_name,
    auxiliaryOccurredAtZone: accident.occurred_at_zone,
    auxiliaryReportedAtZone: accident.reported_at_zone,
    requestResourceName: accident.incident_request_resource_name,
    relatedIncident: accident.related_incident,
    incidentRequestFieldDefinitions: accident.incident_request_field_definitions,
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'status',
    'ehs_investigator_id',
    'internal_party_involved_id',
    'external_party_involved',
    'pi_director_id',
    'supervisor_id',
    'creator_id',
    'supervisor_text',
    'occurred_at',
    'reported_at',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_other',
    'accident_setting_id',
    'description',
    'body_side_affected',
    'osha_risk_factor_id',
    'factors_comment',
    'investigable_id',
    'investigable_type',
    'investigation_start_date',
    'investigation_close_out_date',
    'investigation_text',
    'investigation_recommendations',
    'corrective_actions_due_date',
    'preventive_actions',
    'preventive_actions_due_date',
    'preventive_actions_completion_date',
    'preventive_actions_comments',
    'preventive_actions_recorded_by_id',
    'internal_notes',
    'audit_comment',
    'osha_case_classification',
    'days_away_from_work',
    'days_on_job_transfer_or_restriction',
    'treatment_physician',
    'treatment_outside_worksite',
    'treatment_facility',
    'treatment_street',
    'treatment_city',
    'treatment_state',
    'treatment_zip',
    'employee_treated_in_emergency_room',
    'employee_hospitalized_overnight',
    'occurred_at_zone',
    'reported_at_zone',
    'actions_taken',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
    'person_type_id',
    'affected_body_part_ids',
    'accident_unsafe_act_ids',
    'accident_unsafe_personal_factor_ids',
    'accident_unsafe_condition_ids',
    'accident_class_ids',
    'injury_type_ids',
    'distribution_list_recipient_ids',
    'event_type_id',
    'label_ids',
    'details_attachments',
    'investigation_attachments',
    'internal_attachments',
    'details_links',
    'investigation_links',
    'internal_links',
    'accident_field_answers_attributes',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    accident_field_answers_attributes: prepareCustomFieldsAnswers(
      'incident_request',
      backendValues.incident_request_field_definitions,
      backendValues.accident_field_answers_attributes,
    ),
  }),
});

export const AccidentForm = withFormik({
  mapPropsToValues: props => AccidentFunctions.prepareInitialValues(props.accident),
  handleSubmit: AccidentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
