import { useMemo } from 'react';
import { isDefined } from '../../../../community_portal/assets/helpers/GeneralHelper';

export const useFinalizationDisabled = props => {
  const {
    t,
    values: {
      corrective_actions_due_date,
      corrective_actions_required,
      corrective_actions_completion_date,
      recurrence_prevention_due_date,
      recurrence_prevention_required,
      recurrence_prevention_completion_date,
    },
  } = props;

  const correctionAttempted = useMemo(() => isDefined(corrective_actions_due_date), [
    corrective_actions_due_date,
  ]);
  const preventionAttempted = useMemo(() => isDefined(recurrence_prevention_due_date), [
    recurrence_prevention_due_date,
  ]);

  const correctionCompleted = useMemo(
    () => isDefined(corrective_actions_required) && isDefined(corrective_actions_completion_date),
    [corrective_actions_required, corrective_actions_completion_date],
  );
  const preventionCompleted = useMemo(
    () =>
      isDefined(recurrence_prevention_required) && isDefined(recurrence_prevention_completion_date),
    [recurrence_prevention_required, recurrence_prevention_completion_date],
  );

  const correctionInvalid = useMemo(() => correctionAttempted && !correctionCompleted, [
    correctionAttempted,
    correctionCompleted,
  ]);
  const preventionInvalid = useMemo(() => preventionAttempted && !preventionCompleted, [
    preventionAttempted,
    preventionCompleted,
  ]);

  const finalizationDisabled = useMemo(() => correctionInvalid || preventionInvalid, [
    correctionInvalid,
    preventionInvalid,
  ]);
  const finalizationButtonHint = useMemo(() => {
    if (correctionInvalid) return t('hints.corrective_actions_tooltip');
    if (preventionInvalid) return t('hints.preventative_actions_tooltip');
    return '';
  }, [correctionInvalid, preventionInvalid]);

  return { finalizationDisabled, finalizationButtonHint };
};
