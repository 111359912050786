import React, { useState, useEffect } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { TextField } from '../../../../components/fields/TextField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { AsyncMultiSelectorField } from '../../../../components/fields/AsyncMultiSelectorField';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { isDefined } from '../../../../services/utils';
import { setEventType } from '../setEventType';

export const Overview = props => {
  const {
    t,
    setTypeDetailsLoading,
    defaultChildrenAttributes,
    setFieldValue,
    isSubmitting,
    currentUser: { org_admin: userIsAdmin },
    values: { id, finalized },
  } = props;

  const [warnAboutTypeChange, setWarnAboutTypeChange] = useState(false);
  useEffect(() => {
    if (warnAboutTypeChange) {
      setWarnAboutTypeChange(false);
    }
  }, [isSubmitting]);

  return (
    <FormSection
      title={t('sections.overview')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField name="identifier" disabled component={TextField} />
      <FastField
        name="people_group_id"
        required
        disabled={finalized || (isDefined(id) && !userIsAdmin)}
        api={API.selectors.peopleGroups.index}
        selectionApi={API.selectors.peopleGroups.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField name="created_at" disabled component={DateTimeField} />
      <FastField
        name="incident_status_id"
        required
        api={API.selectors.incidentStatuses.index}
        apiParams={{ open: finalized ? 'false' : 'true' }}
        selectionApi={API.selectors.incidentStatuses.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
      />
      <FastField
        name="distribution_list_recipient_ids"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncMultiSelectorField}
      />
      <FastField
        name="event_type_id"
        api={API.selectors.eventTypes.index}
        selectionApi={API.selectors.eventTypes.show}
        optionRenderer={htmlOptionRenderer('text')}
        component={AsyncSelectorField}
        warning={warnAboutTypeChange ? t('warnings.change_type') : ''}
        onChange={async (newSelection, onChange) => {
          setTypeDetailsLoading(true);
          if (isDefined(id)) {
            setWarnAboutTypeChange(true);
          }
          await onChange(newSelection);
          await setEventType(newSelection, setFieldValue);
          setTypeDetailsLoading(false);
        }}
      />
    </FormSection>
  );
};
