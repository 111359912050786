import React, { useRef } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../../../components/lists/SimpleListLinks';
import { API } from '../../../../services/api';
import { Modal } from '../../../../components/modals/Modal';
import { AddIncidentRequestModalContent } from '../AddIncidentRequestModalContent';

export const RelatedIncidentRequests = props => {
  const {
    t,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
    requestResourceName,
    accessToIncidentRequests,
  } = props;

  const addIncidentRequestModalRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>
            {t('lists.incident_requests.title', { resourceName: requestResourceName })}
          </FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addIncidentRequestModalRef.current.open,
              );
            }}
          >
            {t('lists.incident_requests.add_link', { resourceName: requestResourceName })}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.occurrences.incidents.incidentRequests(id).index}
            ref={listRef}
            dataKey="data"
            mapRowHrefs={
              accessToIncidentRequests &&
              (({ attributes: attribute }) =>
                `/requests/incident_requests/${attribute.incident_request.id}/edit`)
            }
            resourceName={I18n.t('resource_names.incident_requests_incident')}
            mapData={({ attributes }) => {
              return [
                [t('lists.incident_requests.identifier'), attributes.incident_request.identifier],
                [
                  t('lists.incident_requests.datetime_reported'),
                  attributes.incident_request.datetime_reported,
                ],
                [
                  t('lists.incident_requests.event_type_id'),
                  attributes.incident_request.event_type_id,
                ],
                [
                  t('lists.incident_requests.reporting_person_id'),
                  attributes.incident_request.reporting_person_id,
                ],
                <SimpleListDeletionLink
                  key="delete"
                  modelName={t('lists.incident_requests.model_name')}
                  modalWarning={t('lists.incident_requests.unlink_incident_request_warning', {
                    resourceName: requestResourceName,
                  })}
                  modalConfirmationButtonText={t(
                    'lists.incident_requests.unlink_incident_request_confirmation_button',
                  )}
                  onSubmit={async () => {
                    const { ok, data } = await API.occurrences.incidents
                      .incidentRequests(id)
                      .destroy(attributes.id);

                    if (ok) {
                      listRef.current.refresh();
                      showSuccessMessage(
                        t('lists.incident_requests.flash.success.unlink', {
                          resourceName: requestResourceName,
                        }),
                      );
                    } else {
                      showBackendErrorMessage(
                        t('lists.incident_requests.flash.error.unlink', {
                          resourceName: requestResourceName,
                        }),
                        data,
                      );
                    }
                  }}
                >
                  {t('lists.incident_requests.delete_link')}
                </SimpleListDeletionLink>,
              ];
            }}
          />
        )}
      </FormSection>
      <Modal
        title={t('modals.link_incident_request.title', { resourceName: requestResourceName })}
        ref={addIncidentRequestModalRef}
      >
        {modalProps => (
          <AddIncidentRequestModalContent {...modalProps} {...props} listRef={listRef} />
        )}
      </Modal>
    </>
  );
};
