import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { SimpleList } from '../../../components/lists/SimpleList';
import { dateFormatter } from '../../../services/dateFormatUtils';
import { AddRelatedIncidentModalContent } from './AddRelatedIncidentModalContent';

const t = (key, params = {}) => I18n.t(`occurrences.accidents.new_edit.${key}`, params);

export const RelatedIncident = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values,
    values: { id, relatedIncident },
  } = props;

  const addRelatedIncidentModalRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.related_incident.title')}</FormSectionTitle>
          {!relatedIncident && (
            <FormSectionHeaderLink
              disabled={isSubmitting}
              onClick={async () => {
                await handleSubmitWithAfterSave(
                  setFieldValue,
                  handleSubmit,
                  addRelatedIncidentModalRef.current.open,
                );
              }}
            >
              {t('lists.related_incident.add_link')}
            </FormSectionHeaderLink>
          )}
        </FormSectionHeader>
        <SimpleList
          ref={listRef}
          rowHrefs={relatedIncident ? [`/occurrences/incidents/${relatedIncident.id}/edit`] : []}
          data={(relatedIncident ? [relatedIncident] : []).map(incident => [
            [t('lists.related_incident.date'), dateFormatter.date(incident.date)],
            [t('lists.related_incident.issue_type'), incident.issue_type],
            [t('lists.related_incident.responsible_person_id'), incident.responsible_person],
            [t('lists.related_incident.status'), incident.status],
            <SimpleListDeletionLink
              key="delete"
              modelName={t('lists.related_incident.model_name')}
              modalWarning={t('lists.related_incident.unlink_incident_warning')}
              modalConfirmationButtonText={t(
                'lists.related_incident.unlink_incident_confirmation_button',
              )}
              hideModalAfterSubmit
              onSubmit={async () => {
                const { ok, data } = await API.occurrences.accidents.update(id, {
                  accident: values,
                  submit_type: 'unlink_incident',
                });
                if (ok) {
                  setFieldValue('relatedIncident', data.data.attributes.related_incident);
                  showSuccessMessage(t('lists.related_incident.flash.success.delete'));
                } else {
                  showBackendErrorMessage(t('lists.related_incident.flash.error.delete'), data);
                }
              }}
            >
              {t('lists.related_incident.delete_link')}
            </SimpleListDeletionLink>,
          ])}
        />
      </FormSection>
      <Modal title={t('modals.link_incident.title')} ref={addRelatedIncidentModalRef}>
        {modalProps => (
          <AddRelatedIncidentModalContent {...modalProps} {...props} listRef={listRef} />
        )}
      </Modal>
    </>
  );
};
