import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { RichTextField } from '../../../../components/fields/RichTextField';
import { DateField } from '../../../../components/fields/DateField';
import { produceShouldUpdate } from '../../../../services/fieldUtils';

export const CorrectiveActions = props => {
  const { t, defaultChildrenAttributes } = props;

  return (
    <FormSection
      title={t('sections.corrective_actions')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      <FastField
        name="corrective_actions_required"
        component={RichTextField}
        hint={t('hints.corrective_actions_required')}
      />
      <FastField
        name="corrective_actions_due_date"
        component={DateField}
        hint={t('hints.corrective_actions_due_date')}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          [
            'corrective_actions_required',
            'actions_taken',
            'corrective_actions_completion_date',
            'corrective_actions_recorded_by_person_id',
          ],
        )}
      />
      <FastField name="actions_taken" component={RichTextField} />
      <FastField name="corrective_actions_completion_date" component={DateField} />
      <FastField
        name="corrective_actions_recorded_by_person_id"
        api={API.selectors.people.index}
        selectionApi={API.selectors.people.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('full_name')}
        component={AsyncSelectorField}
      />
    </FormSection>
  );
};
