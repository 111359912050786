import React, { useEffect, useState } from 'react';
import { FastField } from 'formik';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { Footer } from './Footer';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { RichTextField } from '../../../components/fields/RichTextField';
import { IncidentRequests } from './IncidentRequests';
import { RelatedIncident } from './RelatedIncident';
import { setEventType } from './setEventType';
import { EventTypeDetails } from './EventTypeDetails';

const t = (key, params = {}) => I18n.t(`occurrences.accidents.new_edit.${key}`, params);

export const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    currentUser,
    enums: { timeZones, bodySideAffected, oshaCaseClassification, open_status, finalized_status },
    values: {
      id,
      finalized,
      identifier,
      occurred_at_zone,
      auxiliaryOccurredAtZone,
      reported_at_zone,
      auxiliaryReportedAtZone,
    },
  } = props;

  const [warnAboutTypeChange, setWarnAboutTypeChange] = useState(false);
  const [typeDetailsLoading, setTypeDetailsLoading] = useState(false);

  useEffect(() => {
    if (warnAboutTypeChange) {
      setWarnAboutTypeChange(false);
    }
  }, [isSubmitting]);

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/occurrences/accidents/${id}/audits` : ''}
        investigable={isDefined(id) && { type: 'Accident', id, identifier }}
        pdfExportLink={
          isDefined(id) ? `/salute_portal/api/occurrences/accidents/accidents/${id}.pdf` : ''
        }
        pdfExportMessage={I18n.t(`flash_message.async_report_render`, {
          user_email: currentUser.email,
        })}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.accident',
          shouldUpdate: defaultShouldUpdate,
          disabled: finalized,
          resourceName: I18n.t('resource_names.accident'),
        }}
      >
        {isDefined(id) && <RelatedIncident {...props} />}
        {isDefined(id) && <IncidentRequests {...props} />}
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="status"
            required
            options={
              finalized
                ? mapToSelectorOptions(finalized_status, 'header', 'value')
                : mapToSelectorOptions(open_status, 'header', 'value')
            }
            component={SelectorField}
          />
          <FastField
            name="distribution_list_recipient_ids"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="creator_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="event_type_id"
            api={API.selectors.eventTypes.index}
            selectionApi={API.selectors.eventTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            warning={warnAboutTypeChange ? t('warnings.change_type') : ''}
            onChange={async (newSelection, onChange) => {
              setTypeDetailsLoading(true);
              if (isDefined(id)) {
                setWarnAboutTypeChange(true);
              }
              await onChange(newSelection);
              await setEventType(newSelection, setFieldValue);
              setTypeDetailsLoading(false);
            }}
          />
        </FormSection>
        <FormSection title={t('sections.summary')}>
          <FastField
            name="internal_party_involved_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="external_party_involved" component={TextField} />
          <FastField
            name="person_type_id"
            required
            api={API.selectors.personTypes.index}
            selectionApi={API.selectors.personTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="pi_director_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="supervisor_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="supervisor_text" component={TextField} />
          <FastField
            name="occurred_at"
            required
            component={DateTimeField}
            timeZones={{
              fixedTimeZone: occurred_at_zone,
              inputTimeZone: auxiliaryOccurredAtZone,
            }}
          />
          <FastField
            name="auxiliaryOccurredAtZone"
            options={mapToSelectorOptions(timeZones, 'header', 'value')}
            component={SelectorField}
            label=""
          />
          <FastField
            name="reported_at"
            required
            component={DateTimeField}
            timeZones={{
              fixedTimeZone: reported_at_zone,
              inputTimeZone: auxiliaryReportedAtZone,
            }}
          />
          <FastField
            name="auxiliaryReportedAtZone"
            options={mapToSelectorOptions(timeZones, 'header', 'value')}
            component={SelectorField}
            label=""
          />
          <LocationFields buildingSelectorAttributes={{ required: true }} {...props} />
          <FastField name="location_other" component={TextField} />
          <FastField
            name="accident_setting_id"
            api={API.selectors.accidentSettings.index}
            selectionApi={API.selectors.accidentSettings.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.details')}>
          <FastField
            name="injury_type_ids"
            api={API.selectors.accidentInjuryTypes.index}
            selectionApi={API.selectors.accidentInjuryTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="description" component={RichTextField} />
          <FastField
            name="accident_class_ids"
            api={API.selectors.accidentClasses.index}
            selectionApi={API.selectors.accidentClasses.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="affected_body_part_ids"
            api={API.selectors.accidentBodyParts.index}
            selectionApi={API.selectors.accidentBodyParts.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="body_side_affected"
            options={mapToSelectorOptions(bodySideAffected, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            name="osha_risk_factor_id"
            api={API.selectors.accidentOshaRiskFactors.index}
            selectionApi={API.selectors.accidentOshaRiskFactors.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="osha_case_classification"
            options={mapToSelectorOptions(oshaCaseClassification, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="days_away_from_work" component={TextField} />
          <FastField name="days_on_job_transfer_or_restriction" component={TextField} />
          <FastField name="details_attachments" component={FilesField} />
          <FastField name="details_links" component={LinksField} />
        </FormSection>
        <FormSection title={t('sections.treatment')}>
          <FastField name="treatment_physician" component={TextField} />
          <FastField name="treatment_outside_worksite" component={CheckBoxField} />
          <FastField name="treatment_facility" component={TextField} />
          <FastField name="treatment_street" component={TextField} />
          <FastField name="treatment_city" component={TextField} />
          <FastField name="treatment_state" component={TextField} />
          <FastField name="treatment_zip" component={TextField} />
          <FastField name="employee_treated_in_emergency_room" component={CheckBoxField} />
          <FastField name="employee_hospitalized_overnight" component={CheckBoxField} />
        </FormSection>
        <FormSection title={t('sections.causal_factors')}>
          <FastField
            name="accident_unsafe_act_ids"
            api={API.selectors.accidentUnsafeActs.index}
            selectionApi={API.selectors.accidentUnsafeActs.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="accident_unsafe_personal_factor_ids"
            api={API.selectors.accidentUnsafePersonalFactors.index}
            selectionApi={API.selectors.accidentUnsafePersonalFactors.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="accident_unsafe_condition_ids"
            api={API.selectors.accidentUnsafeConditions.index}
            selectionApi={API.selectors.accidentUnsafeConditions.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="factors_comment" component={RichTextField} />
          <FastField
            name="label_ids"
            api={API.selectors.labels.index}
            selectionApi={API.selectors.labels.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.corrective_actions')}>
          <FastField name="investigation_recommendations" component={RichTextField} />
          <FastField name="corrective_actions_due_date" component={DateField} />
          <FastField name="investigation_start_date" component={DateField} />
          <FastField name="investigation_close_out_date" component={DateField} />
          <FastField name="actions_taken" component={RichTextField} />
          <FastField name="corrective_actions_completion_date" component={DateField} />
          <FastField
            name="corrective_actions_recorded_by_person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="investigation_text" component={RichTextField} />
          <FastField name="investigation_attachments" component={FilesField} />
          <FastField name="investigation_links" component={LinksField} />
        </FormSection>
        <FormSection title={t('sections.preventative_actions')}>
          <FastField name="preventive_actions" component={RichTextField} />
          <FastField name="preventive_actions_due_date" component={DateField} />
          <FastField name="preventive_actions_comments" component={RichTextField} />
          <FastField name="preventive_actions_completion_date" component={DateField} />
          <FastField
            name="preventive_actions_recorded_by_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.internal_information')}>
          <FastField name="internal_notes" component={RichTextField} />
          <FastField name="internal_attachments" component={FilesField} />
          <FastField name="internal_links" component={LinksField} />
        </FormSection>
        <EventTypeDetails loading={typeDetailsLoading} {...props} />
        <FormSection title={t('sections.resolution')}>
          <FastField
            name="ehs_investigator_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="send_email_notifications" component={CheckBoxField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
