import React, { useMemo, useRef } from 'react';
import { FastField } from 'formik';
import { Tooltip } from 'react-tippy';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { LightButton } from '../../../components/buttons/LightButton';
import { ModalSection } from '../../../components/modals/ModalSection';
import { Modal } from '../../../components/modals/Modal';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { useFinalizationDisabled } from './useFinalizationDisabled';

const TooltipWrapper = ({ children, hint }) => {
  if (!hint) return children;
  return (
    <Tooltip arrow title={hint}>
      {children}
    </Tooltip>
  );
};

export const Footer = props => {
  const {
    t,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id, finalized, audit_comment, finalizationStatusId },
  } = props;

  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const finalizationModalRef = useRef(null);
  const finalizationModalT = useMemo(() => key => t(`modals.finalization.${key}`), [t]);
  const reopenModalRef = useRef(null);
  const reopenModalT = useMemo(() => key => t(`modals.reopen.${key}`), [t]);
  const { finalizationDisabled, finalizationButtonHint } = useFinalizationDisabled(props);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.incident')}
            onSubmit={async () => {
              const { ok, data } = await API.occurrences.incidents.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete'));
                window.location.href = '/occurrences/incidents';
              } else {
                showBackendErrorMessage(t('flash.error.delete'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={finalized}
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <TooltipWrapper hint={finalizationButtonHint}>
            <BrandButton
              hidden={finalized}
              disabled={finalizationDisabled}
              onClick={async () => {
                finalizationModalRef.current.open();
              }}
            >
              {t('footer.finalize')}
            </BrandButton>
          </TooltipWrapper>
          <LightButton
            hidden={!finalized}
            onClick={async () => {
              reopenModalRef.current.open();
            }}
          >
            {t('footer.reopen')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={finalizationModalT('title')} ref={finalizationModalRef}>
        <ModalSection>
          <FastField
            name="finalizationStatusId"
            required
            label={I18n.t('activerecord.attributes.incident.incident_status_id')}
            api={API.selectors.incidentStatuses.index}
            apiParams={{ open: 'false' }}
            selectionApi={API.selectors.incidentStatuses.show}
            optionRenderer={htmlOptionRenderer('text')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            component={AsyncSelectorField}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => finalizationModalRef.current.hide()}>
                {finalizationModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                onClick={async () => {
                  finalizationModalRef.current.hide();
                  await setFieldValue('incident_status_id', finalizationStatusId);
                  await setFieldValue('submitType', 'finalize');
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {finalizationModalT('footer.finalize')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
      <Modal title={reopenModalT('title')} ref={reopenModalRef}>
        <ModalSection>
          <FastField
            name="audit_comment"
            required
            label={reopenModalT('reason')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            component={TextAreaField}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => reopenModalRef.current.hide()}>
                {reopenModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                disabled={!audit_comment}
                onClick={async () => {
                  reopenModalRef.current.hide();
                  await setFieldValue('submitType', 'reopen');
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {reopenModalT('footer.reopen')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
