import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { AccidentForm } from './Form';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';

export const Accident = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [accident, setAccident] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id) ? API.occurrences.accidents.show : API.occurrences.accidents.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setAccident(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <AccidentForm {...props} accident={accident} />;
};
export default Accident;
