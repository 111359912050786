import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  updateBreadcrumbs,
  showSuccessMessage,
  showBackendErrorMessage,
  produceDefaultValidation,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`occurrences.incidents.new_edit.${key}`, params);

const IncidentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const incident = IncidentFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(incident.id)) {
      IncidentFunctions.create(incident, formikProps, values);
    } else {
      IncidentFunctions.update(incident, formikProps, values);
    }
  },
  prepareValuesToSubmit: values => {
    values.date_zone = values.auxiliaryDateZone;
    values.reported_date_zone = values.auxiliaryReportedDateZone;
    values.response_date_zone = values.auxiliaryResponseDateZone;

    return IncidentFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (
    incident,
    formikProps,
    { afterSave, submitType, sendEmailNotifications, defaultFinalizationStatusId },
  ) => {
    const { ok, data } = await API.occurrences.incidents.create({
      incident,
      submit_type: submitType,
      send_email_notifications: sendEmailNotifications,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/occurrences/incidents/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.occurrences.incidents.edit.title'));
      formikProps.resetForm({
        values: IncidentFunctions.prepareInitialValues({
          ...data.data.attributes,
          defaultFinalizationStatusId,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${submitType}`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${submitType}`), data);
    }
  },
  update: async (
    incident,
    formikProps,
    { afterSave, submitType, sendEmailNotifications, defaultFinalizationStatusId },
  ) => {
    const { ok, data } = await API.occurrences.incidents.update(incident.id, {
      incident,
      submit_type: submitType,
      send_email_notifications: sendEmailNotifications,
    });
    if (ok) {
      formikProps.resetForm({
        values: IncidentFunctions.prepareInitialValues({
          ...data.data.attributes,
          defaultFinalizationStatusId,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${submitType}`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${submitType}`), data);
    }
  },
  auxiliaryValues: incident => ({
    finalized: incident.finalized,
    submitType: 'save',
    sendEmailNotifications: !isDefined(incident.id),
    auxiliaryDateZone: incident.date_zone,
    auxiliaryReportedDateZone: incident.reported_date_zone,
    auxiliaryResponseDateZone: incident.response_date_zone,
    finalizationStatusId: incident.defaultFinalizationStatusId,
    defaultFinalizationStatusId: incident.defaultFinalizationStatusId,
    incidentRequestFieldDefinitions: incident.incident_request_field_definitions,
    eventTypeName: incident.event_type_name,
    submissionChannelName: incident.submission_channel_name,
    afterSave: () => {},
  }),
  backendValuesWhitelist: [
    'id',
    'identifier',
    'people_group_id',
    'created_at',
    'incident_status_id',
    'distribution_list_recipient_ids',
    'submission_channel_id',
    'submission_channel_other',
    'recipient_person_id',
    'date',
    'date_zone',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'department_id',
    'caller_person_id',
    'external_caller_name',
    'reported_date',
    'reported_date_zone',
    'follow_up_contact',
    'owner_person_id',
    'issue_type_id',
    'short_description',
    'issue',
    'label_ids',
    'responder_person_id',
    'response_date',
    'response_date_zone',
    'responder_assessment',
    'executive_summary',
    'corrective_actions_required',
    'corrective_actions_due_date',
    'actions_taken',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
    'recurrence_prevention_required',
    'recurrence_prevention_due_date',
    'recurrence_prevention_completion_date',
    'recurrence_prevention_comment',
    'recurrence_prevention_recorded_by_person_id',
    'report_attachments',
    'report_links',
    'other_information',
    'impact_type_id',
    'impact_length',
    'incident_risk_rating_id',
    'cost_implication',
    'report_to_regulatory_agency',
    'attachments',
    'links',
    'current_responsible_person_id',
    'submit_type',
    'audit_comment',
    'incident_field_answers_attributes',
    'event_type_id',
  ],
});

export const IncidentForm = withFormik({
  mapPropsToValues: ({ incident, defaultFinalizationStatusId }) =>
    IncidentFunctions.prepareInitialValues({
      ...incident,
      defaultFinalizationStatusId,
    }),
  handleSubmit: IncidentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
