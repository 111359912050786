import yup from '../../../components/form/CustomYupValidator';
import {
  currentDateTime,
  dateFormatter,
  newMoment,
  getConvertedMoment,
} from '../../../services/dateFormatUtils';

const t = (key, params = {}) => I18n.t(`occurrences.accidents.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  status: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  creator_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  person_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  ehs_investigator_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  occurred_at: yup
    .string()
    .nullable()
    .test(
      'occurred_at_cannot_be_later_than_reported_at',
      t('occurred_at_cannot_be_later_than_reported_at'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(occurredAt) {
        const reportedAt = this.parent.reported_at;

        return occurredAt && reportedAt
          ? getConvertedMoment(occurredAt).isSameOrBefore(getConvertedMoment(reportedAt), 'minute')
          : true;
      },
    )
    .test(
      'occurred_at_cannot_be_later_than_investigation_start_date',
      t('occurred_at_cannot_be_later_than_investigation_start_date'),
      function _(occurredAt) {
        const investigationStartDate = this.parent.investigation_start_date;

        return occurredAt && investigationStartDate
          ? getConvertedMoment(occurredAt).isSameOrBefore(
              getConvertedMoment(investigationStartDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'occurred_at_cannot_be_later_than_investigation_close_out_date',
      t('occurred_at_cannot_be_later_than_investigation_close_out_date'),
      function _(occurredAt) {
        const investigationCloseOutDate = this.parent.investigation_close_out_date;

        return occurredAt && investigationCloseOutDate
          ? getConvertedMoment(occurredAt).isSameOrBefore(
              getConvertedMoment(investigationCloseOutDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'occurred_at_cannot_be_later_than_preventive_actions_due_date',
      t('occurred_at_cannot_be_later_than_preventive_actions_due_date'),
      function _(occurredAt) {
        const preventiveActionsDueDate = this.parent.preventive_actions_due_date;

        return occurredAt && preventiveActionsDueDate
          ? getConvertedMoment(occurredAt).isSameOrBefore(
              getConvertedMoment(preventiveActionsDueDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'occurred_at_cannot_be_later_than_preventive_actions_completion_date',
      t('occurred_at_cannot_be_later_than_preventive_actions_completion_date'),
      function _(occurredAt) {
        const preventiveActionsCompletionDate = this.parent.preventive_actions_completion_date;

        return occurredAt && preventiveActionsCompletionDate
          ? getConvertedMoment(occurredAt).isSameOrBefore(
              getConvertedMoment(preventiveActionsCompletionDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'occurred_at_cannot_be_later_than_current_time',
      t('occurred_at_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function _(occurredAt) {
        return occurredAt ? getConvertedMoment(occurredAt).isSameOrBefore(newMoment()) : true;
      },
    )
    .required(I18n.t('validations.required')),
  investigation_start_date: yup
    .string()
    .nullable()
    .test(
      'investigation_start_date_cannot_be_later_than_investigation_close_out_date',
      t('investigation_start_date_cannot_be_later_than_investigation_close_out_date'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(investigationStartDate) {
        const investigationCloseOutDate = this.parent.investigation_close_out_date;

        return investigationStartDate && investigationCloseOutDate
          ? getConvertedMoment(investigationStartDate, true).isSameOrBefore(
              getConvertedMoment(investigationCloseOutDate, true),
              'minute',
            )
          : true;
      },
    )
    .required(I18n.t('validations.required')),
  reported_at: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  reported_at_zone: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  occurred_at_zone: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
